

















import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import baseFunction from '@/utils/baseData/baseFunction'
import shanDSortView from './shanDSortView.vue'
import shanDHisView from './shanDHisView.vue'

export default Vue.extend({
    components: {
        shanDSortView,
        shanDHisView,
    },
    props: ['lonLat', 'kmList'],
    data() {
        return {
            barList: ['快捷查询', '数据回演'],
            barIndex: 0,
        }
    },
    methods: {
        getThunderRadius(value: any) {
            this.$emit('getThunderRadius', value)
        },
        getThunderMarker(value: any) {
            this.$emit('getThunderMarker', value)
        },
        getThunderTime(value: any) {
            this.$emit('getThunderTime', value)
        },
        changeBar(index: any) {
            this.barIndex = index
        },
    },
})
