































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import daqiData from '../../dataService/daqiData'

const columns = [
    {title: '站点名称', key: 'name'},
    {title: '最高值(v/m)', key: 'highvalue'},
    {title: '最低值(v/m)', key: 'lowvalue'},
    {title: '平均值(v/m)', key: 'average'},
    // {title: '状态', key: 'state'},
    // {title: '强度', key: 'value'},
    // {title: '预警等级', key: 'level'},
    {title: '时间', slot: 'createtime', width: 90},
]
export default Vue.extend({
    data() {
        return {
            starttime: new Date(),
            startTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            endtime: new Date() ,
            endTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            timeSpace: 'ss',
            timeNum: '',
            loading: true,
            alldata: []as any,
            columns,
            data: []as any,

            timer: null as any,
            startParamsTime: '',
            endParamsTime: '',
        }
    },
    mounted() {
        const dateEnd = new Date()
        dateEnd.setHours(dateEnd.getHours() - 1)
        this.starttime = dateEnd
        const date = new Date()
        this.endtime = date
        this.loading = false
    },
    methods: {
        getData() {
            const starttime = moment(this.starttime).format('YYYYMMDDHHmmss')
            const endtime = moment(this.endtime).format('YYYYMMDDHHmmss')
            const params = {
                timeRange: `${starttime},${endtime}`,
            }
            return daqiData.electricDataList(params).then((data) => {
                // console.log(data)
                if (data.length > 0) {
                    data.map((e: any) => {
                        // e.name = '站号' + e.deviceid
                        e.name = '西安输油末站'
                    })
                    this.alldata = data
                }
            //     // this.$emit('getElectricMarker', data)
            //     this.$emit('getElectricTime',
            //         {
            //             starttime: this.startParamsTime,
            //             endtime: this.endParamsTime,
            //         },
            //     )
                this.loading = false
            }).catch((err) => {
                console.log(err)
                this.$Message.error(err)
                this.$emit('getELectricMarker', [])
                this.loading = false
            })
        },
        async start() {
            this.stop()
            if (!this.timeNum) {
                this.$Message.error('时间间隔输入不能为空')
                return
            }
            await this.getData()
            // console.log(this.alldata.length)
            // console.log('++++++++++++++++++++++++++++++++++')
            if (this.alldata.length > 0) {
                this.data = [this.alldata[0]]
                this.$emit('getELectricMarker', this.data)
                this.$emit('getELectricTime',
                    {
                        starttime: (this.data[0].createtime).replace('T', ' '),
                        endtime: '',
                    },
                )
                let index = 0
                this.timer = setInterval(() => {
                    index = index + 1
                    // console.log(index)
                    if (index + 1 >= this.alldata.length) {
                        console.log('--------------止--------------')
                        this.stop()
                    } else {
                        this.data = [this.alldata[index]]
                        this.$emit('getELectricMarker', this.data)
                        this.$emit('getELectricTime',
                            {
                                starttime: (this.data[0].createtime).replace('T', ' '),
                                endtime: '',
                            },
                        )
                    }
                }, 1000 * Number(this.timeNum))
            }
        },
        stop() {
            if (!this.timer) {
                return
            }
            clearInterval(this.timer)
            this.timer = null
        },
        startTimeChange(e: any) {
            this.starttime = e
            this.stop()
        },
        endTimeChange(e: any) {
            this.endtime = e
            this.stop()
        },
        changeTimeSpace(e: any) {
            this.timeNum = ''
            this.stop()
        },
        changeTimeNum(e: any) {
            this.stop()
        },
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },
    filters: {
        momentTime(time: any) {
            // return moment(time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
            return time.replace('T', ' ')
        },
    },
})
