
























































import Vue from 'vue'
import echarts from 'echarts'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import baseFunction from '@/utils/baseData/baseFunction'
import skyconText from '@/utils/baseData/skyconText'
import windElement from '@/utils/baseData/windElement'
import weatherData from '../dataService/weatherData'

export default Vue.extend({
    data() {
        return {
            stationList: [],
            stationObj: {}as any,
            stationId: '',
            liveDefaultdata: []as any,
            livedata: {}as any,
            daydata: []as any,
            hourdata: []as any,
            currentInterval: undefined as any,
        }
    },
    mounted() {
        this.getData()
        if (this.currentInterval) {clearInterval(this.currentInterval)}
        this.currentInterval = setInterval(() => {
            const current = moment(new Date(), 'YYYYMMDDHHmmss').format('mm')
            // console.log(current)
            if (Number(current) < 10) {
                this.getRefreshing()
            }
        }, 1000 * 60)
    },
    methods: {
        async getRefreshing() {
            await this.getLive()
            // console.log(this.livedata)
            // console.log(this.liveDefaultdata)
            if (this.livedata.datetime !== this.liveDefaultdata.datetime) {
                this.livedata = this.liveDefaultdata
            }
        },
        getData() {
            Promise.all([
                this.getStationInfo(),
            ]).then(() => {
                // console.log(this.stationList)
                // console.log(this.stationObj)
                this.stationObj = this.stationList[0]
                this.stationId = this.stationObj.station_id_c
                Promise.all([
                    this.getLive(),
                    this.getHourForcast(),
                    this.getDayForcast(),
                ]).then(() => {
                    this.livedata = this.liveDefaultdata
                })
            })
        },
        selectStation() {
            this.stationList.map((e: any) => {
                if (this.stationId === e.station_id_c) {
                    this.stationObj = e
                }
            })
            Promise.all([
                this.getLive(),
                this.getHourForcast(),
                this.getDayForcast(),
            ]).then(() => {
                this.livedata = this.liveDefaultdata
            })
            this.$emit('getStationObj', this.stationObj)
        },
        async getDayForcast() {
            // const caiYun = await this.getCaiyunData()
            let caiYun = []as any
            let wpdata = []as any
            Promise.all([
                this.getCaiyunData().then((res) => {caiYun = res}),
                weatherData.dayWeatherWp(this.stationObj.lon, this.stationObj.lat, 16).then((res) => {wpdata = res}),
            ]).then(() => {
                // console.log(caiYun)
                // console.log(wpdata)
                const weekDayArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                weatherData.dayWeatherData(this.stationObj.lon, this.stationObj.lat, 8).then((data) => {
                // console.log(data)
                data.map((e: any, index: any) => {
                    const t1 = moment().add(index, 'd').format('YYYYMMDD')
                    const t2 = moment().add(index + 1, 'd').format('YYYYMMDD')
                    if (!wpdata || wpdata.length === 0) {return}
                    let wp1 = null
                    let wp2 = null
                    wpdata.map((i: any) => {
                        const {dataTime, wp12} = i
                        if (dataTime === `${t1}200000`) {wp1 = wp12}
                        else if (dataTime === `${t2}080000`) {wp2 = wp12}
                    })
                    e.wp = [wp1, wp2]
                })
                data.map((e: any, index: any) => {
                    const dataTime = moment(e.dataTime, 'YYYYMMDDHHmmss').subtract(1, 'days').format('YYYY-MM-DD')
                    const date = new Date(dataTime)
                    e.weekDay = weekDayArr[date.getDay()]
                    e.date =  moment(e.dataTime, 'YYYYMMDDHHmmss').subtract(1, 'days').format('MM/DD')
                    // const wp = e.wp24 < 10 ? 'd0' + e.wp24 : 'd' + e.wp24
                    // e.imgUrl = `/static/weatherIcon/${wp}.png`
                    // e.skyconText = skyconText.weatherIcon(wp)
                    e.imgUrl = e.wp[0] === 0 ? `/static/weatherIcon/d0${e.wp[0]}.png` : !e.wp[0] ? '/static/weatherIcon/--.png' : e.wp[0] < 10 ? `/static/weatherIcon/d0${e.wp[0]}.png` : `/static/weatherIcon/d${e.wp[0]}.png`
                    if (caiYun && caiYun.length > 0) {
                        caiYun.map((i: any, caiIndex: any) => {
                            if (index === caiIndex) {
                                e.speed = i.speed
                                e.direction = i.direction
                            }
                        })
                    }
                })
                // console.log(data)
                this.daydata = data.slice(1, 11)
                this.createChart(
                    this.daydata.map((e: any) => e.date),
                    this.daydata.map((e: any) => e.tmax24),
                    this.daydata.map((e: any) => e.tmin24),
                );
            })
            })
        },
        getHourForcast() {
            weatherData.hourWeatherData(this.stationObj.lon, this.stationObj.lat).then((data) => {
                // console.log(data)
                const current = moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHH0000')
                let num = 0
                data.map((e: any, index: any) => {
                    // const img = e.wp < 10 ? 'd0' + e.wp : 'd' + e.wp
                    // e.imgUrl = `/static/weatherIcon/${img}.png`
                    // e.time = moment(e.dataTime, 'YYYYMMDDHHmmss').format('HH:mm')
                    // e.skyconText = skyconText.weatherIcon(img)
                    if (e.dataTime === Number(current)) {
                        num = index
                        const wp = e.wp < 10 ? 'd0' + e.wp : 'd' + e.wp
                        this.$set(this.livedata, 'imgUrl', `/static/weatherIcon/${wp}.png`)
                        this.$set(this.livedata, 'skyconText', skyconText.weatherIcon(wp))
                    }
                })
                // this.hourdata = data.slice(num, num + 12)
                // console.log(this.livedata)
            })
        },
        getLive() {
            // console.log(this.stationObj)
            return weatherData.liveWeatherData(this.stationObj.station_id_c).then((data) => {
                // console.log(data)
                if (data.length > 0) {
                    const dataObj = data[0]
                    const time = moment(dataObj.datetime, 'YYYYMMDDHHmmss').add(+8, 'hours').format('YYYY年MM月DD日 HH时')
                    return this.liveDefaultdata = {
                        datetime: dataObj.datetime,
                        updateTime: time,
                        tem: baseFunction.getValue(dataObj.tem),
                        pre: baseFunction.getValue(dataObj.pre_1h),
                        rhu: baseFunction.getValue(dataObj.rhu),
                        prs: baseFunction.getValue(dataObj.prs),
                        wind: baseFunction.getValue(dataObj.win_s_avg_2mi),
                        vis: baseFunction.getValue(dataObj.vis / 1000),
                    }
                }
            })
        },
        getStationInfo() {
            return weatherData.stationInfoList().then((data) => {
                // console.log(data)
                this.stationList = data
            })
        },
        getCaiyunData() {
            const url = `/v2.5/0woAfhHWQi4n5X9N/${this.stationObj.lon},${this.stationObj.lat}/weather.json?dailysteps=8`
            return request.get(url).then((data) => {
                const {wind} =  data.result.daily
                const caiYun = wind
                // console.log(caiYun)
                const dataArr = []as any
                caiYun.map((e: any) => {
                    const {speed, direction} = e.avg
                    dataArr.push({
                        speed: windElement.windLevel(speed),
                        direction: windElement.windDirection(direction),
                    })
                })
                // console.log(dataArr)
                return dataArr
            }).catch((err) => {
                console.log(err)
                return []
            })
        },
        createChart(data: any, value1: any, value2: any) {
            // @ts-ignore
            const myChart = this.$echarts.init(document.getElementById('main')); // 基于准备好的dom，初始化echarts实例
            window.onresize = myChart.resize;  // 图表宽度自适应
            const colors = ['#EC9E41', '#00E9FF'];
            const option = {
                color: colors,
                grid: {
                    x: 20,
                    y: 30,
                    x2: 20,
                    y2: 0,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                    data,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    name: '温度/℃',
                    nameTextStyle: {
                        color: '#000',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#000',
                        fontSize: 16,
                    },
                    axisLine: {
                        lineStyle: {color: colors[0]},
                    },
                },
                series: [
                    {
                        name: '最高温度',
                        type: 'line',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {color: '#EC9E41', fontSize: 16},
                                },
                                lineStyle: {
                                    width: 4,
                                    color: '#EC9E41',
                                },
                            },
                        },
                        data: value1,
                    },
                    {
                        name: '最低温度',
                        type: 'line',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'bottom',
                                    textStyle: {color: '#00E9FF', fontSize: 16},
                                },
                                lineStyle: {
                                    width: 4,
                                    color: '#00E9FF',
                                },
                            },
                        },
                        data: value2,
                    },
                ],
            };
            myChart.setOption(option);
        },
    },
    destroyed() {
        if (this.currentInterval) {clearInterval(this.currentInterval)}
    },
})
