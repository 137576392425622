const windLevel = (speed: any) => {
    let level;
    if (speed <= 0.2) {
        level = 0;
    } else if (speed > 0.2 && speed <= 1.5) {
        level = 1;
    } else if (speed > 1.5 && speed <= 3.3) {
        level = 2;
    } else if (speed > 3.3 && speed <= 5.4) {
        level = 3;
    } else if (speed > 5.4 && speed <= 7.9) {
        level = 4;
    } else if (speed > 7.9 && speed <= 10.7) {
        level = 5;
    } else if (speed > 10.7 && speed <= 13.8) {
        level = 6;
    } else if (speed > 13.8 && speed <= 17.1) {
        level = 7;
    } else if (speed > 17.1 && speed <= 20.7) {
        level = 8;
    } else if (speed > 20.7 && speed <= 24.4) {
        level = 9;
    } else if (speed > 24.4 && speed <= 28.4) {
        level = 10;
    } else if (speed > 28.4 && speed <= 32.6) {
        level = 11;
    } else if (speed > 32.6 && speed <= 36.9) {
        level = 12;
    }
    return level;
}
const windDirection = (idirection: any) => {
    let windeDirection;
    if (0 <= idirection && idirection < 45) {
        windeDirection = '北';
    } else if (45 <= idirection && idirection < 90) {
        windeDirection = '东北';
    } else if (90 <= idirection && idirection < 135) {
        windeDirection = '东';
    } else if (135 <= idirection && idirection < 180) {
        windeDirection = '东南';
    } else if (180 <= idirection && idirection < 225) {
        windeDirection = '南';
    } else if (225 <= idirection && idirection < 270) {
        windeDirection = '西南';
    } else if (270 <= idirection && idirection < 315) {
        windeDirection = '西';
    } else if (315 <= idirection && idirection <= 360) {
        windeDirection = '西北';
    } else {
        windeDirection = '--';
    }
    return windeDirection;
}
export default {windLevel, windDirection}
