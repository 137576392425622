const stationInfoList = [
    { id: '57044', name: '秦陵', lon: 109.26, lat: 34.39},
    { id: 'V8510', name: '斜口', lon: 109.147, lat: 34.35},
    { id: 'V8511', name: '骊山', lon: 109.204, lat: 34.37},
    { id: 'V8522', name: '北田', lon: 109.194, lat: 34.46},
    { id: 'V8523', name: '雨金', lon: 109.238, lat: 34.524},
    { id: 'V8524', name: '西泉', lon: 109.14, lat: 34.41},
    { id: 'V8525', name: '行者', lon: 109.1934, lat: 34.406},
    { id: 'V8526', name: '新丰', lon: 109.277, lat: 34.448},
    { id: 'V8527', name: '小金', lon: 109.421, lat: 34.347},
    { id: 'V8528', name: '铁炉', lon: 109.43, lat: 34.401},
    { id: 'V8529', name: '马额', lon: 109.39, lat: 34.411},
    { id: 'V8531', name: '任留', lon: 109.242, lat: 34.48},
    { id: 'V8532', name: '油槐', lon: 109.38, lat: 34.556},
    { id: 'V8533', name: '栎阳', lon: 109.1965, lat: 34.58},
    { id: 'V8551', name: '零口', lon: 109.36, lat: 34.475},
    { id: 'V8552', name: '交口', lon: 109.2963, lat: 34.571},
    { id: 'V8554', name: '相桥', lon: 109.3856, lat: 34.612},
    { id: 'V8557', name: '徐杨', lon: 109.163, lat: 34.615},
    { id: 'V8581', name: '代王', lon: 109.32, lat: 34.41},
    { id: 'V8582', name: '何寨', lon: 109.3305, lat: 34.516},
    { id: 'V8583', name: '新市', lon: 109.18, lat: 34.53},
    { id: 'V8585', name: '穆寨', lon: 109.362, lat: 34.337},
    { id: 'V8586', name: '仁宗', lon: 109.28, lat: 34.338},
]
const stationMap: any = {}
stationInfoList.forEach((e) => {
    stationMap[e.id] = e
})
export default stationMap
