






























import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import monitoring from './dataComponents/monitoring.vue'
import liveForest from './dataComponents/liveForest.vue'
import warningView from './dataComponents/warningView.vue'
import mapMain from './dataComponents/mapMain.vue'
import daqiDianchangTable from './dataComponents/daqi/daqiDianchangTable.vue'
import shandianTable from './dataComponents/shandian/shandianTable.vue'

export default Vue.extend({
    components: {
        monitoring,
        liveForest,
        warningView,
        mapMain,
        daqiDianchangTable,
        shandianTable,
    },
    props: ['warnNewInfo'],
    data() {
        return {
            lonLat: []as any,
            thunderMarker: [],
            thunderTime: {},
            oildepotMarker: []as any,
            electricMarker: []as any,
            eLectricTime: '',
            mapCircle: []as any,
            kmList: []as any,
            warnNum: [],
        }
    },
    created() {
        Promise.all([
            // 获取油库信息
            this.getOildepotData(),
            // 获取大气电场仪经纬度getLonLat...
            this.getElectricData(),
            // 闪电预警范围
            this.getWarnRangeData(),
        ]).then(() => {
            // console.log(123)
        })
    },
    methods: {
        getWarnNum(value: any) {
            this.warnNum = value
        },
        getThunderMarker(value: any) {
            this.thunderMarker = value
        },
        getThunderTime(value: any) {
            this.thunderTime = value
        },
        getThunderRadius(value: any) {
            this.mapCircle.map((e: any) => {
                e.radius = Number(value)
            })
        },
        getELectricMarker(value: any) {
            if (value && value.length > 0) {
                this.electricMarker = [
                    {
                        name: value[0].name,
                        imgIcon: '/static/image/monitor/legend_1.png',
                        lon: '109.13478330776976',
                        lat: '34.3400605877569',
                        value: value[0].average,
                    },
                ]
            } else {this.electricMarker = [
                    {
                        // name: '站号20024',
                        name: '西安输油末站',
                        imgIcon: '/static/image/monitor/legend_1.png',
                        lon: '109.13478330776976',
                        lat: '34.3400605877569',
                        value: '',
                    },
                ]
            }
        },
        getELectricTime(value: any) {
            this.eLectricTime = value
        },
        getOildepotData() {
            this.oildepotMarker = [
                {
                    name: '陕西延长石油（集团）有限责任公司管道运输第五分公司输油末站',
                    // name: '陕西延长石油第五分公司',
                    imgIcon: '/static/image/monitor/legend_3.png',
                    lon: '109.13278330776976',
                    lat: '34.3380605877569',
                },
            ]
        },
        getElectricData() {
            const electricMarker = [
                {
                    name: '大气电场1',
                    imgIcon: '/static/image/monitor/legend_1.png',
                    lon: '109.13478330776976',
                    lat: '34.3400605877569',
                },
            ]
            this.lonLat = [Number(electricMarker[0].lon),  Number(electricMarker[0].lat)]
            // console.log(this.lonLat)
            this.mapCircle = []
            electricMarker.map((e: any) => {
                this.mapCircle.push({
                    center: [e.lat, e.lon],
                    radius: 0,
                })
            })
        },
        getWarnRangeData() {
            this.kmList = ['5km', '10km', '20km', '50km']
            // return request.get('/admin/warning_threshold/query?element=lightning&status=1', null)
            // .then((data) => {
            //     // console.log(data.data)
            //     if (data && data.data.length > 0) {
            //         data.data.map((e: any) => {
            //             this.kmList.push(`${e.maxValue}km`)
            //         })
            //     }
            // })
        },
    },
})
