




































import Vue from 'vue'
import moment from 'moment/moment'
import radarJS from '../dataService/radarJS'
export default Vue.extend({
  props: ['radarAlltime'],
  data() {
    return {
      isPlay: false,
      index: 0,
      time: '',
      setInterval: 0,
    }
  },
  created() {
      this.index = this.radarAlltime.length - 1
      // console.log(this.index)
      // console.log(this.radarAlltime[this.index])
      this.setItem()
  },
  destroyed() {
    this._stop()
  },
  filters: {
    getTime(value: any) {
      return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
    },
    getMonthTime(value: any) {
      const M = value.split(' ')[0].split('-')[1]
      const D = value.split(' ')[0].split('-')[2]
      const H = value.split(' ')[1].split(':')[0]
      const m = value.split(' ')[1].split(':')[1]
      return `${M}/${D} ${H}:${m}`
    },
  },
  methods: {
    _start() {
      this._next()
      this.setInterval = setInterval(() => this._next(), 1000)
    },
    _stop() {
      if (!this.setInterval) {
        return
      }
      clearInterval(this.setInterval)
      this.setInterval = 0
    },
    _previous() {
      if (!this.radarAlltime || this.radarAlltime.length === 0) {
        return
      }
      if (this.index > 0) {
        this.index--
      } else {
        this.index = this.radarAlltime.length - 1
      }
      this.setItem()
    },
    _next() {
      if (!this.radarAlltime || this.radarAlltime.length === 0) {
        return
      }
      if (this.index + 1 < this.radarAlltime.length) {
        this.index++
      } else {
        this.index = 0
      }
      this.setItem()
    },
    on(type: string) {
      switch (type) {
        case 'play' :
          this.isPlay = !this.isPlay
          if (this.setInterval) {
            this._stop()
          } else {
            this._start()
          }
          break
        case 'previous':
          this.isPlay = false
          this._stop()
          this._previous()
          break
        case 'next':
          this.isPlay = false
          this._stop()
          this._next()
          break
      }
    },
    setItem() {
      if (!this.radarAlltime || this.radarAlltime.length === 0) {
        return
      }
      const item: any = this.radarAlltime[this.index]
      this.$emit('getImageGroupChange', item)
    },
  },
})
