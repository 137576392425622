

















import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import daqiData from '../../dataService/daqiData'

const columns = [
    {title: '站点名称', key: 'name'},
    {title: '最高值(v/m)', key: 'highvalue'},
    {title: '最低值(v/m)', key: 'lowvalue'},
    {title: '平均值(v/m)', key: 'average'},
    {title: '时间', slot: 'createtime', width: 90},
]
export default Vue.extend({
    data() {
        return {
            loading: true,
            columns,
            data: []as any,
            status: '',
            currentInterval: undefined as any,
        }
    },
    mounted() {
        this.loading = false
        this.getData()
        if (this.currentInterval) {clearInterval(this.currentInterval)}
        this.currentInterval = setInterval(this.getData, 1000 * 60)
    },
    methods: {
        getData() {
            const params = {
                // times: moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss'),
            }
            daqiData.electricDataList(params).then((data) => {
                // console.log(data)
                if (data.length > 0) {
                    data.map((e: any) => {
                        // e.name = '站号' + e.deviceid
                        // e.imgIcon = '/static/image/monitor/legend_2_gif.gif'
                        e.name = '西安输油末站'
                    })
                    this.status = data[0].status
                    this.data = data
                    this.$emit('getELectricMarker', data)
                    this.$emit('getELectricTime',
                        {
                            starttime: (data[0].createtime).replace('T', ' '),
                            endtime: '',
                        },
                    )
                }
                this.loading = false
            }).catch((err) => {
                console.log(err)
                this.$Message.error(err)
                this.$emit('getELectricMarker', [])
                this.loading = false
            })
        },
    },
    filters: {
        momentTime(time: any) {
            // return moment(time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
            // return moment(new Date(), 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
            return time.replace('T', ' ')
        },
    },
    destroyed() {
        if (this.currentInterval) {clearInterval(this.currentInterval)}
    },
})
