
























import Vue from 'vue'
import echarts from 'echarts'
import moment, { months } from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import daqiData from '../../dataService/daqiData'
const barList = [
    {id: 1, text: '1h'},
    {id: 3, text: '3h'},
    {id: 6, text: '6h'},
    {id: 12, text: '12h'},
    {id: 24, text: '24h'},
]

export default Vue.extend({
    data() {
        return {
            barList,
            barId: 1,
            data: []as any,
            electricData: []as any,
        }
    },
    async mounted() {
        await this.getElectricData()
        this.getData()
    },
    methods: {
        changeBar(id: any) {
            this.barId = id
            this.getData()
        },
        getData() {
            // console.log(this.electricData)
            const start = moment(new Date(), 'YYYYMMDDHHmmss').subtract(this.barId, 'hours').format('YYYYMMDDHHmmss')
            const end = moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss')
            const timeRange = `${start},${end}`
            const params = {
                timeRange,
            }
            daqiData.electricDataList(params).then((data) => {
                // console.log(data)
                if (data.length > 0) {
                    data.map((e: any) => {
                        e.name = '站号' + e.deviceid
                        // e.imgIcon = '/static/image/monitor/legend_2_gif.gif'
                        e.date = e.createtime.split('T')[1]
                        e.value = e.average
                    })
                    const arr = data
                    this.$emit('getELectricMarker', [])
                    this.$emit('getELectricTime',
                        {
                            starttime: start,
                            endtime: end,
                        },
                    )
                    const piecesData1 = []as any
                    const piecesData2 = []as any
                    const colorArr = ['#fff', '#E4FF00', '#FFAE00', '#FF0000']
                    this.electricData.map((e: any, index: any) => {
                        piecesData1.push({
                            gt: -e.maxValue * 1000, lte: -e.minValue * 1000, color: colorArr[index + 1],
                        })
                        piecesData2.push({
                            gt: e.minValue * 1000, lte: e.maxValue * 1000, color: colorArr[index + 1],
                        })
                    })
                    piecesData1.unshift({
                        gt: this.electricData[0].minValue * -1000, lte: this.electricData[0].minValue * 1000, color: colorArr[0]
                    })
                    const piecesData = piecesData1.reverse().concat(piecesData2)
                    // const piecesData = [
                    //     {gt: 0, lte: 50, color: '#00A0E9'},
                    //     {gt: 50, lte: 100, color: '#E4FF00'},
                    //     {gt: 100, lte: 150, color: '#FFAE00'},
                    //     {gt: 150, lte: 500, color: '#FF0000'},
                    // ]
                    // console.log(piecesData1)
                    // console.log(piecesData2)
                    // console.log(piecesData)
                    this.createChart(
                        arr.map((e: any) => e.date),
                        arr.map((e: any) => e.value),
                        piecesData,
                    );
                }
            }).catch((err) => {
                console.log(err)
                this.$Message.error(err)
                this.$emit('getELectricMarker', [])
            })
            // const arr = [
            //     {date: '00', value: 3},
            //     {date: '05', value: 5},
            //     {date: '10', value: 17},
            //     {date: '15', value: 6},
            //     {date: '20', value: 14},
            //     {date: '25', value: 2},
            //     {date: '30', value: 4},
            //     {date: '35', value: 9},
            // ]
        },
        createChart(date: any, value: any, piecesData: any) {
            // @ts-ignore
            const myChart = this.$echarts.init(document.getElementById('chart')); // 基于准备好的dom，初始化echarts实例
            window.onresize = myChart.resize;  // 图表宽度自适应
            const colors = ['#EC9E41', '#00E9FF'];
            const option = {
                color: colors,
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff',
                        },
                    },
                },
                grid: {
                    x: 40,
                    y: 10,
                    x2: 20,
                    y2: 20,
                },
                xAxis: {
                    type: 'category',
                    show: true,
                    boundaryGap: true,
                    data: date,
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 14,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {color: '#fff'},
                    },
                    axisTick: {
                        show: false,
                    },
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            // show: false,
                            color: '#000',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    show: true,
                    // name: '温度/℃',
                    // nameTextStyle: {
                    //     color: '#fff',
                    // },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 14,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {color: '#fff'},
                    },
                    axisTick: {
                        show: false,
                    },
                    axisPointer: {
                        type: 'line',
                        label: {
                            // show: false,
                            color: '#000',
                        },
                    },
                },
                visualMap: {
                    top: 10,
                    right: 10,
                    show: false,
                    pieces: piecesData,
                    outOfRange: {
                        color: '#999',
                    },
                },
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        // itemStyle: {
                        //     normal: {
                        //         label: {
                        //             show: true,
                        //             position: 'top',
                        //             textStyle: {color: '#fff', fontSize: 16},
                        //         },
                        //         lineStyle: {
                        //             width: 4,
                        //             color: '#00E9FF',
                        //         },
                        //     },
                        // },
                        data: value,
                        // markLine: {
                        //     silent: true,
                        //     lineStyle: {
                        //         color: '#808080'
                        //     },
                        //     data: [
                        //         {yAxis: 5},
                        //         {yAxis: 10},
                        //         {yAxis: 15},
                        //         {yAxis: 20},
                        //     ],
                        // },
                    },
                ],
            };
            myChart.setOption(option);
        },
        getElectricData() {
            return request.get('/admin/warning_threshold/query?element=electric_field_intensity&status=1', null)
            .then((data) => {
                // console.log(data.data)
                this.electricData = data.data
            })
        },
    },
    filters: {
        getTime(data: any) {
            return moment(data, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY/MM/DD HH:mm:ss')
        },
    },
})
