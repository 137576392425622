
































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import shandianData from '../../dataService/shandianData'
import signUtils from '@/utils/sign_utils'

const barList = [
    {id: 1, text: '1h'},
    {id: 3, text: '3h'},
    {id: 6, text: '6h'},
    {id: 12, text: '12h'},
    {id: 24, text: '24h'},
]
const columns = [
    {title: '经度', key: 'lon'},
    {title: '纬度', key: 'lat'},
    {title: '时间', slot: 'datetime', width: 90},
    {title: '高度(km)', key: 'height'},
    {title: '强度(kA)', key: 'lit_current'},
    {title: '陡度(kA/μs)', key: 'mars_3'},
]
export default Vue.extend({
    props: ['lonLat', 'kmList'],
    watch: {
        kmList() {
            this.kmIndex = this.kmList.length - 1
            this.getData()
            if (this.currentInterval) {clearInterval(this.currentInterval)}
            this.currentInterval = setInterval(this.getData, 1000 * 60)
        },
    },
    data() {
        return {
            // kmList: ['5km', '10km', '20km', '50km'],
            kmIndex: 0,
            barList,
            barId: 24,
            loading: true,
            columns,
            data: []as any,
            positiveNum: 0,
            negativeNum: 0,
            currentInterval: undefined as any,
        }
    },
    mounted() {
        if (this.kmList && this.kmList.length > 0) {
            this.kmIndex = this.kmList.length - 1
            this.getData()
        }
    },
    methods: {
        getData() {
            const {kmList, kmIndex, barId} = this
            // console.log(kmList[kmIndex])
            // console.log(barId)
            const startTime = moment(new Date(), 'YYYYMMDDHHmmss').subtract(barId + 8, 'hours').format('YYYYMMDDHHmmss')
            const endTime = moment(new Date(), 'YYYYMMDDHHmmss').subtract(8, 'hours').format('YYYYMMDDHHmmss')
            const km = (kmList[kmIndex]).split('km')
            const radius = `${km[0]}000`
            this.$emit('getThunderRadius', radius)
            console.log(startTime)
            const params = {
                timeRange: `${startTime},${endTime}`,
                lon: this.lonLat[0],
                lat: this.lonLat[1],
                // timeRange: '20220527072933,20220527122933',
                // lon: 105.268,
                // lat: 33.067,
                radius,
            }
            const href = window.location.href
            const str = href.split(':').slice(0, 2).join(':')
            // if (str === 'http://localhost') {
            //     signUtils(params)
            // }
            signUtils(params)
            // console.log(params)
            shandianData.thunderData(params).then((data) => {
                
                if (data.length > 0) { 
                  data =  JSON.parse(data)
                    const arr1 = data.filter((e: any) => {
                        return e.lit_current > 0
                    })
                    const arr2 = data.filter((e: any) => {
                        return e.lit_current < 0
                    })
                    // console.log(arr1)
                    // console.log(arr2)
                    this.positiveNum = arr1.length
                    this.negativeNum = arr2.length
                    data.map((e: any) => {
                        e.imgIcon = '/static/image/monitor/legend_2_gif.gif'
                    })
                }
                this.data = data
                this.$emit('getThunderMarker', data)
                this.$emit('getThunderTime',
                    {
                        starttime: moment(new Date(), 'YYYYMMDDHHmmss').subtract(barId, 'hours').format('YYYYMMDDHHmmss'),
                        endtime: moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHHmmss'),
                    },
                )
                this.loading = false
            }).catch((err) => {
                console.log(err)
                this.$Message.error(err)
                this.$emit('getThunderMarker', [])
                this.loading = false
            })
        },
        changeKm(index: any) {
            this.kmIndex = index
            this.getData()
        },
        changeBar(id: any) {
            this.barId = id
            this.getData()
        },
    },
    filters: {
        momentTime(time: any) {
            return moment(time, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
    },
    destroyed() {
        if (this.currentInterval) {clearInterval(this.currentInterval)}
    },
})
