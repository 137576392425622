

































































































































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import polygonService from '@/utils/polygonService'
import station_info from '@/utils/station_info'
import layerList from '../dataService/layerList.json'
import radarJS from '../dataService/radarJS'

import allPolygonData from '@/assets/json/610115.json'


import imageController from './part_image_controller.vue'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LMap, LFeatureGroup, LTileLayer, LPolygon, LMarker, LIcon, LGeoJson, LImageOverlay, LPopup, LCircle } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
L.Icon.Default.imagePath = 'leaflet/dist/images/'
const options = {
  subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
  key: '2497f799f5a1aedb1e21e265f8801321',
}
// const url1 = 'http://t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}'
// const url2 = 'http://t{s}.tianditu.com/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk={key}'
// const url3 = 'http://t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}'
// const url4 = 'http://t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}'
// const url1 = '/DataServer{s}?T=vec_w&X={x}&Y={y}&L={z}&tk={key}'
// const url2 = '/DataServer{s}?T=cva_w&X={x}&Y={y}&L={z}&tk={key}'
// const url3 = '/DataServer{s}?T=img_w&X={x}&Y={y}&L={z}&tk={key}'
// const url4 = '/DataServer{s}?T=cia_w&X={x}&Y={y}&L={z}&tk={key}'

const colorList = [
  { color: 'rgb(173, 144, 240)', text: '70' },
  { color: 'rgb(120, 0, 132)', text: '65' },
  { color: 'rgb(255, 0, 240)', text: '60' },
  { color: 'rgb(192, 0, 0)', text: '55' },
  { color: 'rgb(214, 0, 0)', text: '50' },
  { color: 'rgb(255, 0, 0)', text: '45' },
  { color: 'rgb(255, 144, 0)', text: '40' },
  { color: 'rgb(231, 192, 0)', text: '35' },
  { color: 'rgb(255, 255, 0)', text: '30' },
  { color: 'rgb(1, 144, 0)', text: '25' },
  { color: 'rgb(0, 200, 0)', text: '20' },
  { color: 'rgb(1, 255, 0)', text: '15' },
  { color: 'rgb(0, 236, 236)', text: '10' },
  { color: 'rgb(1, 160, 246)', text: '5' },
]
export default Vue.extend({
  components: {
    LMap,
    LFeatureGroup,
    LTileLayer,
    LPolygon,
    LMarker,
    LIcon,
    LGeoJson,
    LImageOverlay,
    LCircle,
    imageController,
    //   LPopup,
    //   LPolyline,
  },
  props: ['thunderMarker', 'thunderTime', 'oildepotMarker', 'electricMarker', 'eLectricTime', 'mapCircle'],
  watch: {
    thunderMarker() {
      // console.log(this.thunderMarker)
      this.playVideo()
    },
    thunderTime() {
      const { starttime, endtime } = this.thunderTime
      const start = moment(starttime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      if (endtime) {
        const end = moment(endtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
        this.thunderTimeRange = `${start} 至 ${end}`
      } else {
        this.thunderTimeRange = `${start}`
      }
    },
    eLectricTime() {
      // console.log(this.eLectricTime)
      const { starttime, endtime } = this.eLectricTime
      const start = moment(starttime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
      if (endtime) {
        const end = moment(endtime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
        this.eLectricTimeRange = `${start} 至 ${end}`
      } else {
        this.eLectricTimeRange = `${start}`
      }
    },
  },
  data() {
    return {
      zoom: 10,
      center: [config.defaultStationObj.lat, config.defaultStationObj.lon],
      option: {
        zoomSnap: 0.2, attributionControl: false, zoomControl: false,
        dragging: true, scrollWheelZoom: true,
      },
      options,
      // url1, url2, url3, url4,
      url1: 'http://t{s}.tianditu.com/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={key}',
      url2: 'http://t{s}.tianditu.com/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk={key}',
      url3: 'http://t{s}.tianditu.com/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk={key}',
      url4: 'http://t{s}.tianditu.com/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk={key}',
      flag: 0,
      polygon: [] as any,
      allPolygon: [] as any,
      polygonSubData: [] as any,
      showSpin: true,
      tilelayerList: JSON.parse(JSON.stringify(layerList)).slice(6, 9),
      layerId: '',
      isPalyVideo: true,
      videoPalySrc: '/static/image/monitor/hornPlay.png',
      videoPaly: true,
      thunderTimeRange: '',
      eLectricTimeRange: '',
      radarShow: false,
      radarInfo: {} as any,
      radarAlltime: [],
      overlayUrl: '' as any,
      overlayBounds: [],
      stationList: [] as any,
      colorList,
    }
  },
  mounted() {
    const polygonSubData = [
      require('@/assets/json/610115001000.json'),
      require('@/assets/json/610115002000.json'),
      require('@/assets/json/610115003000.json'),
      require('@/assets/json/610115004000.json'),
      require('@/assets/json/610115005000.json'),
      require('@/assets/json/610115006000.json'),
      require('@/assets/json/610115007000.json'),
      require('@/assets/json/610115008000.json'),
      require('@/assets/json/610115009000.json'),
      require('@/assets/json/610115010000.json'),
      require('@/assets/json/610115011000.json'),
      require('@/assets/json/610115012000.json'),
      require('@/assets/json/610115013000.json'),
      require('@/assets/json/610115014000.json'),
      require('@/assets/json/610115015000.json'),
      require('@/assets/json/610115016000.json'),
      require('@/assets/json/610115017000.json'),
      require('@/assets/json/610115018000.json'),
      require('@/assets/json/610115019000.json'),
      require('@/assets/json/610115020000.json'),
      require('@/assets/json/610115021000.json'),
      require('@/assets/json/610115022000.json'),
      require('@/assets/json/610115023000.json'),
    ]
    for (const key in station_info) {
      if (Object.prototype.hasOwnProperty.call(station_info, key)) {
        const element = station_info[key];
        // console.log(element)
        this.stationList.push(element)
      }
    }
    // console.log(station_info)
    // console.log(this.stationList)
    // this.polygon = polygonService.polygon('610115')[0]
    this.allPolygon = allPolygonData.map((e: number[]) => [e[1], e[0]]),
      this.polygonSubData = polygonSubData
    this.showSpin = false
  },
  methods: {
    changeRadar() {
      this.radarShow = !this.radarShow
      if (this.radarShow === true) {
        this.getRadar()
      } else {
        this.overlayUrl = ''
        this.overlayBounds = []
      }
    },
    async getRadar() {
      Promise.all([
        radarJS.radarInfo().then((data) => this.radarInfo = data),
        radarJS.radarAlltime().then((data) => this.radarAlltime = data),
      ]).then(() => {
        // console.log(this.radarInfo)
        // console.log(this.radarAlltime)
      })
    },
    getImageGroupChange(value: any) {
      const { radarInfo } = this
      const imageBounds: any = [[radarInfo.latMin, radarInfo.lonMin], [radarInfo.latMax, radarInfo.lonMax]]
      // this.overlayUrl = `http://124.114.232.106:8787/api/radar/image?siteCode=XiAn&time=${value}`
      // this.overlayUrl = `https://ltld.sxktqx.com/api/radar/image?siteCode=XiAn&time=${value}`
      this.overlayBounds = imageBounds
      radarJS.radarImage(value).then((data) => {
        this.overlayUrl = data
      })
      // console.log(this.overlayBounds)
      // console.log(this.overlayUrl)
    },
    clickAudio() {
      this.isPalyVideo = !this.isPalyVideo
      this.playVideo()
    },
    playVideo() {
      // console.log(this.thunderMarker)
      // console.log(this.videoPaly)
      this.videoPaly = true
      const total = this.thunderMarker.length
      if (total === 0) {
        this.videoPaly = false
      }
      const myAudio: any = document.getElementById('buttonAudio');
      if (this.isPalyVideo) {
        this.videoPalySrc = '/static/image/monitor/hornPlay.png'
        if (this.videoPaly === true) {
          myAudio.play();
        } else {
          myAudio.pause();
        }
      } else {
        this.videoPalySrc = '/static/image/monitor/hornMute.png'
        myAudio.pause();
      }
    },
    changeLayer(id: any) {
      // console.log(id)
      this.flag = id
      this.tilelayerList.map((e: any) => {
        if (e.id === id) {
          e.checked = true
        } else {
          e.checked = false
        }
      })
    },
    mouseEnter(id: any) {
      this.layerId = id
    },
    mouseLeave() {
      this.layerId = 'null'
    },
    enlarge() {
      this.zoom += 0.2
    },
    narrow() {
      this.zoom -= 0.2
    },
    markerChange(mapObj: any) {
      // console.log(mapObj.latlng.lat)
      // console.log(mapObj.latlng.lng)\
    },
    getMarkerObj(value: any) {
      // console.log(value)
      // const data = {
      //     cnty: value.cnty,
      //     lat: value.lat,
      //     lon: value.lon,
      //     station_id_c: value.station_id_c,
      //     station_name: value.station_name,
      //     centerTag: 'liveForest',
      // }
      // this.$emit('getStationObj', data)
    },
  },
})
