


















import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import baseFunction from '@/utils/baseData/baseFunction'
import daqiLiveView from './daqiLiveView.vue'
import daqiSortView from './daqiSortView.vue'
import daqiHisView from './daqiHisView.vue'

export default Vue.extend({
    components: {
        daqiLiveView,
        daqiSortView,
        daqiHisView,
    },
    data() {
        return {
            barList: ['实况图', '时序图', '数据回演'],
            barIndex: 0,
        }
    },
    mounted() {
        Promise.all([
            // this.getliveWeather(),
            // this.getData()
        ]).then(() => {
            // console.log()
        })
    },
    methods: {
        changeBar(index: any) {
            this.barIndex = index
        },
        getELectricMarker(value: any) {
            this.$emit('getELectricMarker', value)
        },
        getELectricTime(value: any) {
            this.$emit('getELectricTime', value)
        },
    },
})
