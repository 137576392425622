




























import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import baseFunction from '@/utils/baseData/baseFunction'
import weatherData from '../dataService/weatherData'

export default Vue.extend({
    props: ['warnNum'],
    watch: {
        warnNum() {
            this.getData()
        },
    },
    data() {
        return {
            monitorData: []as any,
        }
    },
    mounted() {
        this.getData()
        // this.getMonitor()
    },
    methods: {
        getData() {
            // console.log(this.warnNum)
            this.warnNum.map((e: any) => {
                this.monitorData.push(
                    String(e),
                )
            })
            // console.log(this.monitorData)
        },
        getMonitor() {
            weatherData.liveMonitorData().then((data) => {
                // console.log(data)
                // data.map((e: any) => {
                //     if (e.iymdhm) {
                //         const datetime = moment(e.datetime, 'YYYYMMDDHHmmss').
                // add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
                //         const iymdhm = moment(e.iymdhm, 'YYYYMMDDHHmmss').
                // add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
                //         const d1 = new Date(datetime)
                //         const d2 = new Date(iymdhm)
                //         const x = d2.getTime() - d1.getTime(); // 毫秒
                //         const num = (x / 1000 / 60).toFixed(2)
                //         e.MonitorState = baseFunction.getState(num)
                //     } else {
                //         e.MonitorState = 2
                //     }
                // })
                const arrNum = [[], [], []]as any
                data.map((e: any) => {
                    if (e.MonitorState === 0) {arrNum[0].push(e.station_id_c)}
                    if (e.MonitorState === 1) {arrNum[1].push(e.station_id_c)}
                    if (e.MonitorState === 2 || e.state === -1) {arrNum[2].push(e.station_id_c)}
                })
                // console.log(arrNum)
                // this.monitorData = [
                //     String(arrNum[0].length),
                //     String(arrNum[1].length),
                //     String(arrNum[2]),
                // ]
            })
        },
    },
})
