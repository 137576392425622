import config from '@/utils/config'
import signUtils from '@/utils/sign_utils'
import requestGet from '@/utils/requestGet'

// 雷达
const radarInfo = () => {
    const params = {
        siteCode: config.siteCode,
    }
    signUtils(params)
    return requestGet.get('/radar/info', params)
    .then((data) => {
        if (data) {
            return data
        }
        return []
    }).catch((err) => {
        console.log(err)
        return []
    })
}

const radarAlltime  = () => {
    const params = {
        siteCode: config.siteCode,
    }
    signUtils(params)
    return requestGet.get('/radar/all_time', params)
    .then((data) => {
        if (data) {
            return data
        }
        return []
    }).catch((err) => {
        console.log(err)
        return []
    })
}

const radarImage = (time: any) => {
    const params = {
        siteCode: config.siteCode,
        time,
    }
    signUtils(params)
    return requestGet.getBlob('/radar/image', params)
    .then((data) => {
        // console.log(data)
        // let blob = new Blob([data.data]);
        // console.log(blob)
        if (data) {
            return URL.createObjectURL(data.data)
        }
        return ''
    }).catch((err) => {
        console.log(err)
        // 将Blob 对象转换成字符串
        const reader = new FileReader();
        reader.readAsText(err.response.data, 'utf-8');
        console.error(reader.result)
        return ''
    })
    // return requestGet.get('/radar/image', params)
    // .then((data) => {
    //     if (data) {
    //         return data
    //     }
    //     return []
    // }).catch((err) => {
    //     console.log(err)
    //     return []
    // })
}
export default {
    radarInfo,
    radarAlltime,
    radarImage,
}
