















































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import shandianData from '../../dataService/shandianData'
import signUtils from '@/utils/sign_utils'

const columns = [
    {title: '经度', key: 'lon'},
    {title: '纬度', key: 'lat'},
    {title: '时间', slot: 'datetime', width: 90},
    {title: '高度(km)', key: 'height'},
    {title: '强度(kA)', key: 'lit_current'},
    {title: '陡度(kA/μs)', key: 'mars_3'},
]
export default Vue.extend({
    props: ['lonLat', 'kmList'],
    data() {
        return {
            // kmList: ['5km', '10km', '20km', '50km'],
            kmIndex: 0,
            starttime: new Date(),
            startTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            endtime: new Date() ,
            endTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            timeSpace: 'hh',
            timeNum: '',
            loading: true,
            columns,
            data: []as any,
            positiveNum: 0,
            negativeNum: 0,
            timer: null as any,
            startParamsTime: '',
            endParamsTime: '',
        }
    },
    mounted() {
        const dateEnd = new Date()
        dateEnd.setHours(dateEnd.getHours() - 24)
        this.starttime = dateEnd
        const date = new Date()
        this.endtime = date
        this.loading = false
    },
    methods: {
        getData(startTime: any, endTime: any) {
            this.positiveNum = 0
            this.negativeNum = 0
            const {kmList, kmIndex} = this
            const km = (kmList[kmIndex]).split('km')
            const radius = `${km[0]}000`
            this.$emit('getThunderRadius', radius)
            const s = moment(startTime, 'YYYYMMDDHHmmss').subtract(8, 'hours').format('YYYYMMDDHHmmss')
            const e = moment(endTime, 'YYYYMMDDHHmmss').subtract(8, 'hours').format('YYYYMMDDHHmmss')
            const params = {
                timeRange: `${s},${e}`,
                lon: this.lonLat[0],
                lat: this.lonLat[1],
                radius,
            }
            const href = window.location.href
            const str = href.split(':').slice(0, 2).join(':')
            // if (str === 'http://localhost') {
            //     signUtils(params)
            // }
            signUtils(params)
            // console.log(params)
            shandianData.thunderData(params).then((data) => {
                // console.log(data)
                if (data.length > 0) {
                    const arr1 = data.filter((i: any) => {
                        return i.lit_current > 0
                    })
                    const arr2 = data.filter((i: any) => {
                        return i.lit_current < 0
                    })
                    // console.log(arr1)
                    // console.log(arr2)
                    this.positiveNum = arr1.length
                    this.negativeNum = arr2.length
                    data.map((i: any) => {
                        i.imgIcon = '/static/image/monitor/legend_2_gif.gif'
                    })
                }
                // console.log(data)
                this.data = data
                this.$emit('getThunderMarker', data)
                this.$emit('getThunderTime',
                    {
                        starttime: this.startParamsTime,
                        endtime: this.endParamsTime,
                    },
                )
                this.loading = false
            }).catch((err) => {
                console.log(err)
                this.$Message.error(err)
                this.$emit('getThunderMarker', [])
                this.loading = false
            })
        },
        start() {
            this.stop()
            if (!this.timeNum) {
                this.$Message.error('时间间隔输入不能为空')
                return
            }
            const {timeSpace} = this
            let addStr = ''as any
            if (timeSpace === 'hh') {
                if (Number(this.timeNum) > 24) {
                    this.timeNum = '24'
                }
                addStr = 'hours'
            }
            if (timeSpace === 'mm') {
                if (Number(this.timeNum) > 24 * 60) {
                    this.timeNum = String(24 * 60)
                }
                addStr = 'minutes'
            }
            if (timeSpace === 'ss') {
                if (Number(this.timeNum) > 24 * 60 * 60) {
                    this.timeNum = String(24 * 60 * 60)
                }
                addStr = 'seconds'
            }
            const starttime = moment(this.starttime).format('YYYYMMDDHHmmss')
            const endtime = moment(this.endtime).format('YYYYMMDDHHmmss')
            // console.log(this.timeNum)
            this.startParamsTime = moment(this.starttime).format('YYYYMMDDHHmmss')
            this.endParamsTime = moment(this.starttime).add(this.timeNum, addStr).format('YYYYMMDDHHmmss')
            this.getData(this.startParamsTime, this.endParamsTime)
            // console.log(this.startParamsTime)
            // console.log(this.endParamsTime)
            // console.log('++++++++++++++++++++++++++++++++++')
            this.timer = setInterval(() => {
                if (this.endParamsTime >= endtime) {
                    console.log('--------------止--------------')
                    this.stop()
                } else {
                    this.startParamsTime = moment(this.startParamsTime, 'YYYYMMDDHHmmss').add(this.timeNum, addStr).format('YYYYMMDDHHmmss')
                    this.endParamsTime = moment(this.startParamsTime, 'YYYYMMDDHHmmss').add(this.timeNum, addStr).format('YYYYMMDDHHmmss')
                    this.getData(this.startParamsTime, this.endParamsTime)
                    // console.log('==========================')
                    // console.log(this.startParamsTime)
                    // console.log(this.endParamsTime)
                }
            }, 1000)
        },
        stop() {
            if (!this.timer) {
                return
            }
            clearInterval(this.timer)
            this.timer = null
        },
        changeKm(index: any) {
            this.kmIndex = index
            this.stop()
        },
        startTimeChange(e: any) {
            this.starttime = e
            this.stop()
        },
        endTimeChange(e: any) {
            this.endtime = e
            this.stop()
        },
        changeTimeSpace(e: any) {
            this.timeNum = ''
            this.stop()
        },
        changeTimeNum(e: any) {
            this.stop()
        },
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },
    filters: {
        momentTime(time: any) {
            return moment(time, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
    },
})
