














































import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import request from '@/utils/request'
import weatherData from '../dataService/weatherData'

export default Vue.extend({
    data() {
        return {
            // barList: ['大气电场仪预警', '闪电定位预警', '雷暴预警', '气象预警'],
            barList: [ '大气电场仪', '闪电定位', '雷暴','气象'],
            barIndex: 0,
            weatherData: [],
            warningData0: []as any,
            warningData1: []as any,
            warningData2: []as any,
            dataObj: {},
            currentInterval: undefined as any,
            modal: false,
        }
    },
    mounted() {
        this.getData()
        if (this.currentInterval) {clearInterval(this.currentInterval)}
        this.currentInterval = setInterval(this.getData, 1000 * 60)
    },
    methods: {
        getData() {
            Promise.all([
                this.getWeatherData(),
                this.getWarnData(),
            ]).then(() => {
                const arr = [0, 0, 0, 0]
                // console.log(this.weatherData)
                // console.log(this.warningData0)
                // console.log(this.warningData1)
                // console.log(this.warningData2)
                if (this.weatherData && this.weatherData.length > 0) {
                    arr[3] = this.weatherData.length
                }
                if (this.warningData0 && this.warningData0.length > 0) {
                    arr[0] = this.warningData0.length
                }
                if (this.warningData1 && this.warningData1.length > 0) {
                    arr[1] = this.warningData1.length
                }
                if (this.warningData2 && this.warningData2.length > 0) {
                    arr[2] = this.warningData2.length
                    this.modal = true
                }
                // console.log(arr)
                this.$emit('getWarnNum', arr)
            })
        },
        changeBar(index: any) {
            this.barIndex = index
        },
        getWarnData() {
            return request.get('/admin/warning/list').then((res) => {
            // request.get('/warninfo/getEarlyWarnHisInfo', params).then( res => {
                // console.log(res)
                if (res.data.length > 0) {
                    res.data.map((e: any) => {
                        if (e.type === 'electric_field') {
                            this.warningData0 = e.warningList
                        } else if (e.type === 'lightning') {
                            this.warningData1 = e.warningList
                        } else if (e.type === 'thunderstorm') {
                            this.warningData2 = e.warningList
                        }
                    })
                }
            })
        },
        getWeatherData() {
            const params = {
                areaCode: config.defaultStationObj.adminCode + '000000',
                // startTime: '2022-04-01',
                // endTime: '2022-04-28',
            }
            // console.log(params)
            return request.get('/warninfo/getEarlyWarnNewInfo', params).then((res) => {
            // request.get('/warninfo/getEarlyWarnHisInfo', params).then( res => {
                // console.log(res.result.alarms)
                if (res.result.alarms.length > 0) {
                    this.weatherData = res.result.alarms
                }
                // console.log(this.warnInfo)
            })
        },
        clickModal(value: any) {
            // console.log(value)
        },
    },
    destroyed() {
        if (this.currentInterval) {clearInterval(this.currentInterval)}
    },
})
