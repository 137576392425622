// 判断状态
const getState = (num: any) => {
    let state = 0
    if (num <= 10) {
        state = 0 // 及时
    } else if (num > 10 && num <= 30) {
        state = 1 // 迟报
    } else if (num > 30) {
        state = 2 // 缺报
    }
    return state
}
// 判断空值
const getValue = (data: any) => {
    if (data === 0 || data === '0') {
        return data
    } else if (!data || data === undefined) {
        return '--'
    } else {
        return data
    }
}
// 排序方法
const getChartSort = (json: any, key: any) => {
    for (let j = 1, jl = json.length; j < jl; j++) {
        const temp = json[j]
        const val = temp[key]
        let i = j - 1;
        while (i >= 0 && json[i][key] > val) {
            json[i + 1] = json[i];
            i = i - 1;
        }
        json[i + 1] = temp;
    }
    return json;
}
const getSortValue = (value: any, ele: any) => {
    if (value || value === 0 || value === '0') {
        return value
    } else if (!value || value === undefined) {
        if (ele === 'tem_min') {
            return 999
        } else {
            return -999
        }
    } else {
        return value
    }
}
export default {
    getState,
    getValue,
    getChartSort,
    getSortValue,
}
